<template>
  <!-- 车辆管理 -->
  <div class="outer-page">
    <x-table
      :no-data-text="CA('car_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @add="add"
      @page-change="pageChange"
      @loadEnter="loadEnter"
      @loadExpend="loadExpend"
      @page-size-change="pageSizeChange"
    >
    </x-table>
    <Modal
      v-model="modal.show"
      :title="modal.title"
      :width="900"
      @on-visible-change="modalShow"
    >
      <Form :label-width="100" ref="form" :model="form" :rules="rules">
        <Row>
          <Col span="11">
            <FormItem label="车牌号" prop="plate">
              <Input
                clearable
                placeholder="请填写车辆车牌号"
                v-model="form.plate"
              ></Input>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="车辆类型" prop="carType">
              <!-- <Input
                clearable
                placeholder="请填写车辆类型"
                v-model="form.carType"
                style="width: 260px"
              ></Input> -->
              <Select
                v-model="form.carType"
                placeholder="请选择车辆类别"
                clearable
              >
                <Option
                  v-for="(item, index) in carType"
                  :key="index"
                  :value="item.name"
                >
                  {{ item.name }}
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="车辆品牌" prop="carModel">
              <Select
                v-model="form.carModel"
                placeholder="请选择车辆品牌"
                clearable
              >
                <Option
                  v-for="(item, index) in carModel"
                  :key="index"
                  :value="item.name"
                >
                  {{ item.name }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <!-- <FormItem label="车辆所属" prop="type">
              <Select
                v-model="form.type"
                style="width: 260px"
                placeholder="请选择车辆所属"
              >
                <Option :value="1">个人</Option>
                <Option :value="2">公司</Option>
              </Select>
            </FormItem> -->
            <FormItem label="车辆颜色" prop="carColor">
              <Select v-model="form.carColor" placeholder="请填写车辆颜色">
                <Option
                  v-for="color in colors"
                  :key="color.id"
                  :value="color.name + ''"
                >
                  {{ color.name }}
                </Option>
              </Select>
              <!-- <Input
                clearable
                placeholder="请填写车辆颜色"
                v-model="form.carColor"
              ></Input> -->
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="联系人" prop="telName">
              <Input
                clearable
                placeholder="请填写联系人"
                v-model="form.telName"
              ></Input>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="联系电话" prop="telPhone">
              <Input
                clearable
                placeholder="请填写联系电话"
                v-model="form.telPhone"
              ></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="厢长" prop="lasting">
              <Input placeholder="请填写车辆厢长" v-model="form.lasting">
                <span slot="append">米</span>
              </Input>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="厢宽" prop="wide">
              <Input placeholder="请填写车辆厢宽" v-model="form.wide">
                <span slot="append">米</span>
              </Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="厢高" prop="high">
              <Input placeholder="请填写车辆厢高" v-model="form.high">
                <span slot="append">米</span>
              </Input>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="载方" prop="loadSide">
              <Input placeholder="请填写车辆载方" v-model="form.loadSide">
                <span slot="append">方</span>
              </Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="载重" prop="loadHeavy">
              <Input placeholder="请填写车辆载重" v-model="form.loadHeavy">
                <span slot="append">吨</span>
              </Input>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="整车高度" prop="allHigh">
              <Input placeholder="请填写车辆整车高度" v-model="form.allHigh">
                <span slot="append">米</span>
              </Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="GPS品牌" prop="gpsType">
              <Select v-model="form.gpsType" placeholder="请选择GPS品牌">
                <Option
                  v-for="gps in GPSList"
                  :key="gps.id"
                  :value="gps.name + ''"
                >
                  {{ gps.name }}
                </Option>
              </Select>
              <!-- <Input
                clearable
                placeholder="请填写车辆GPS品牌"
                v-model="form.gpsType"
              ></Input> -->
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="GPS型号" prop="gpsModel">
              <Input
                clearable
                placeholder="请填写车辆GPS型号"
                v-model="form.gpsModel"
              ></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="交强险期限" prop="clivtaExpire">
              <DatePicker
                type="date"
                placeholder="请选择交强险期限"
                v-model="form.clivtaExpire"
                style="width: 298px"
              ></DatePicker>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="商业险期限" prop="vehicleInsurance">
              <DatePicker
                type="date"
                placeholder="请选择商业险期限"
                v-model="form.vehicleInsurance"
                style="width: 298px"
              ></DatePicker>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="年审日期" prop="annualReview">
              <DatePicker
                type="date"
                placeholder="请选择年审日期"
                v-model="form.annualReview"
                style="width: 298px"
              ></DatePicker>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem
              :show-message="false"
              style="margin-bottom: 0px"
              label="车辆图片"
            >
              <upload-image
                :max="1"
                :clear-flag="!modal.show"
                v-model="form.imgUrl"
              >
              </upload-image>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit">提交</Button>
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
    <car-detail
      v-model="carDetail.show"
      :carDetailInfo="carDetail.info"
    ></car-detail>
    <pic-view v-model="checkPic" :src="imgSrc"></pic-view>
    <driver-bind
      v-model="driverDetail.show"
      :driverInfo="driverDetail.info"
    ></driver-bind>
  </div>
</template>

<script>
import carDetail from "./carDetail";
import driverBind from "./driverBind";
import moment from "moment";
import picView from "@/components/picture_view";
import CU from "@/common/util";
export default {
  name: "",
  components: {
    carDetail,
    picView,
    driverBind,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "图片",
            width: 100,
            align: "center",
            render: (h, { row }) => {
              if (row.imgUrl !== "img/car.png" && row.imgUrl !== "") {
                return (
                  <div
                    style="width:60px;height:60px;padding:4px;cursor:pointer"
                    on-click={() => this.checkPicInfo(row)}
                  >
                    <img
                      style="width:100%;height:100%"
                      src={this.getImgUrl(row.servicePath, row.imgUrl)}
                    />
                  </div>
                );
              } else {
                return (
                  <div
                    style="width:60px;height:60px;padding:4px;cursor:pointer"
                    on-click={() => this.checkPicInfo(row)}
                  >
                    <img
                      style="width:100%;height:100%"
                      src="/imgs/carLogo.jpg"
                    />
                  </div>
                );
              }
            },
          },
          // {
          //   title: "车辆类型",
          //   key: "carType",
          // },
          // {
          //   title: "车辆品牌",
          //   key: "carModel",
          // },
          {
            title: "车辆品牌",
            align: "center",
            key: "carModel",
          },
          {
            title: "车辆类型",
            align: "center",
            width: 200,
            key: "carType",
          },
          // {
          //   title: "GPS品牌",
          //   key: "gpsType",
          // },
          // {
          //   title: "GPS型号",
          //   key: "gpsModel",
          // },
          {
            title: "车牌号/颜色",
            align: "center",
            render: (h, { row }) => {
              return <span>{row.plate + " ( " + row.carColor + " ) "}</span>;
            },
            key: "plate",
          },
          // {
          //   title: "车辆颜色",
          //   align: "center",
          //   key: "carColor",
          // },
          {
            title: "厢长 / 厢宽 / 厢高(米)",
            align: "center",
            width: 220,
            render: (h, { row }) => {
              return (
                <span>{row.lasting + " × " + row.wide + " × " + row.high}</span>
              );
            },
          },
          // {
          //   title: "箱宽",
          //   align: "center",
          //   render: (h, { row }) => {
          //     return <span>{`${row.wide} 米`}</span>;
          //   },
          // },
          // {
          //   title: "厢高",
          //   align: "center",
          //   render: (h, { row }) => {
          //     return <span>{`${row.high} 米`}</span>;
          //   },
          // },
          // {
          //   title: "载方",
          //   align: "center",
          //   render: (h, { row }) => {
          //     return <span>{`${row.loadSide} 方`}</span>;
          //   },
          // },
          // {
          //   title: "载重",
          //   align: "center",
          //   render: (h, { row }) => {
          //     return <span>{`${row.loadHeavy} 吨`}</span>;
          //   },
          // },
          {
            title: "载方 / 载重",
            align: "center",
            render: (h, { row }) => {
              return (
                <span>
                  {`${row.loadSide} 方`}，{`${row.loadHeavy} 吨`}
                </span>
              );
            },
          },
          {
            title: "整车高度",
            align: "center",
            render: (h, { row }) => {
              return <span>{`${row.allHigh} 米`}</span>;
            },
          },
          {
            title: "归属企业",
            width: 300,
            align: "center",
            key: "companyName",
            tooltip: true,
            // render: (h, { row }) => {
            //   return <span>{row.type == 1 ? "个人" : "公司"}</span>;
            // },
          },
          {
            title: "操作",
            width: 300,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("car_stop") && row.status == 1 && (
                    <Poptip
                      confirm
                      transfer
                      title="确定停用吗?"
                      on-on-ok={() => this.stop(row.id)}
                    >
                      <a style="margin-right: 10px">停用</a>
                    </Poptip>
                  )}
                  {this.CA("car_open") && row.status == 2 && (
                    <Poptip
                      confirm
                      transfer
                      title="确定启用吗?"
                      on-on-ok={() => this.open(row.id)}
                    >
                      <a style="margin-right: 10px">启用</a>
                    </Poptip>
                  )}
                  {this.CA("car_update") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("car_view") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.checkInfo(row)}
                    >
                      车辆详情
                    </a>
                  )}
                  {this.CA("car_driverBind") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.driverBind(row)}
                    >
                      司机绑定
                    </a>
                  )}
                  {this.CA("car_delete") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.delete(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },

      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      carDetail: {
        show: false,
        info: {},
      },
      colors: [],
      GPSList: [],
      form: {
        id: "",
        plate: "",
        carType: "",
        carModel: "",
        gpsType: "",
        gpsModel: "",
        telName: "",
        telPhone: "",
        annualReview: "",
        clivtaExpire: "",
        vehicleInsurance: "",
        carColor: "",
        useUserId: "",
        imgUrl: "",
        type: 2,
        //厢长
        lasting: "",
        //箱宽
        wide: "",
        //厢高
        high: "",
        //载方
        loadSide: "",
        //载重
        loadHeavy: "",
        //整车高度
        allHigh: "",
      },
      rules: {
        plate: [
          { required: true, message: "请填写车牌号" },
          {
            validator(rule, value, callback) {
              let reg =
                /^([京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][1-9DF][1-9ABCDEFGHJKLMNPQRSTUVWXYZ]\d{3}[1-9DF]|[京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][\dABCDEFGHJKLNMxPQRSTUVWXYZ]{5})$/;
              if (!reg.test(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "请填写正确的车牌号",
          },
        ],
        carType: [{ required: true, message: "请选择车辆类型" }],
        carModel: [{ required: true, message: "请填写车辆品牌" }],
        // gpsType: [{ required: true, message: "请填写GPS品牌" }],
        // gpsModel: [{ required: true, message: "请填写GPS型号" }],
        telName: [{ required: true, message: "请填写联系人" }],
        telPhone: [
          { required: true, message: "请填写联系电话" },
          {
            validator(rule, value, callback) {
              if (!/^1[3|4|5|7|8|9][0-9]{9}$/.test(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "手机号格式不正确",
            trigger: "change",
          },
        ],
        // annualReview: [{ required: true, message: "请选择年审日期" }],
        // clivtaExpire: [{ required: true, message: "请选择交强险期限" }],
        // vehicleInsurance: [{ required: true, message: "请选择商业险期限" }],
        carColor: [
          { required: true, message: "请填写车辆颜色" },
          // {
          //   validator(rule, value, callback) {
          //     let temp = ("" + value).trim();
          //     if (!temp) callback();
          //     if (/[a-zA-Z0-9]/.test(temp)) callback(rule.message);
          //     else callback();
          //   },
          //   message: "请输入中文颜色",
          // },
        ],
        type: [{ required: true, message: "请选择车辆所属" }],
        lasting: [
          { required: true, message: "请选择车辆厢长" },
          {
            validator(rule, value, callback) {
              let isNumber = CU.validateNumber(value, 0, undefined, 4);
              if (isNumber) callback();
              else callback(rule.message);
            },
            message: "请填写正确数字",
          },
        ],
        wide: [
          { required: true, message: "请选择车辆箱宽" },
          {
            validator(rule, value, callback) {
              let isNumber = CU.validateNumber(value, 0, undefined, 4);
              if (isNumber) callback();
              else callback(rule.message);
            },
            message: "请填写正确数字",
          },
        ],
        high: [
          { required: true, message: "请选择车辆厢高" },
          {
            validator(rule, value, callback) {
              let isNumber = CU.validateNumber(value, 0, undefined, 4);
              if (isNumber) callback();
              else callback(rule.message);
            },
            message: "请填写正确数字",
          },
        ],
        loadSide: [
          { required: true, message: "请选择车辆载方" },
          {
            validator(rule, value, callback) {
              let isNumber = CU.validateNumber(value, 0, undefined, 4);
              if (isNumber) callback();
              else callback(rule.message);
            },
            message: "请填写正确数字",
          },
        ],
        loadHeavy: [
          { required: true, message: "请选择车辆载重" },
          {
            validator(rule, value, callback) {
              let isNumber = CU.validateNumber(value, 0, undefined, 4);
              if (isNumber) callback();
              else callback(rule.message);
            },
            message: "请填写正确数字",
          },
        ],
        allHigh: [
          { required: true, message: "请选择车辆整车高度" },
          {
            validator(rule, value, callback) {
              let isNumber = CU.validateNumber(value, 0, undefined, 4);
              if (isNumber) callback();
              else callback(rule.message);
            },
            message: "请填写正确数字",
          },
        ],
      },
      search_data: {},
      //车辆类别
      carType: [],
      //车辆品牌
      carModel: [],
      //查看车辆图片
      checkPic: false,
      //车辆图片地址
      imgSrc: "",
      driverDetail: {
        show: false,
        info: {},
      },
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增车辆",
            ca: "car_add",
          },
          width: 220,
          filterBox: [
            {
              conditionName: "车牌号",
              component: "input",
              field: "plate",
              defaultValue: "",
            },
            {
              conditionName: "车辆类型",
              component: "select",
              field: "carType",
              defaultValue: "",
              data: this.carType,
              parameterField: "name",
              showField: "name",
            },
            {
              conditionName: "车辆品牌",
              component: "select",
              field: "carModel",
              defaultValue: "",
              data: this.carModel,
              parameterField: "name",
              showField: "name",
            },
            {
              conditionName: "归属企业",
              component: "input",
              field: "companyName",
              defaultValue: "",
            },
            {
              conditionName: "联系人",
              component: "input",
              field: "telName",
              defaultValue: "",
            },
            {
              conditionName: "联系电话",
              component: "input",
              field: "telPhone",
              defaultValue: "",
            },
          ],
          loadModel: {
            url: "https://castoff.hogdata.cn/app/粪污车辆信息.xlsx",
          },
          loadEnter: {
            loadName: "导入",
          },
          loadExpend: {
            loadExpendName: "导出",
          },
        },
        page: { ...this.page },
      };
      return config;
    },
  },
  methods: {
    //颜色列表
    getCarColors() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: "504",
      }).then((res) => {
        this.colors = res.list;
      });
    },
    //gps列表
    getGPSList() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: "514",
      }).then((res) => {
        this.GPSList = res.list;
      });
    },
    // 导入
    loadEnter() {
      let input = document.createElement("input");
      input.type = "file";
      input.onchange = (e) => {
        if (!input.files || input.files.length === 0) return;
        let formdata = new FormData();
        formdata.append("file", input.files[0]);
        formdata.append("userId", localStorage.getItem("userId"));
        formdata.append("companyNo", localStorage.getItem("companyNo"));
        this.$post(this.$api.CAR_MANAGE.UPLOAD, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then((res) => {
          this.$Message.success("导入成功");
          this.getList();
        });
      };
      input.click();
    },
    search(data) {
      this.search_data = data;
      this.page.pageNo = 1;
      this.getList();
    },

    add() {
      this.modal = {
        show: true,
        title: "新增车辆信息",
        submitLoading: false,
      };
    },

    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },

    getList() {
      if (!this.CA("car_check")) return;
      this.table.loading = true;
      this.$post(this.$api.CAR_MANAGE.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    stop(id) {
      this.$post(this.$api.CAR_MANAGE.STOP, {
        id,
      }).then(() => {
        this.$Message.success("停用成功");
        this.getList();
      });
    },
    open(id) {
      this.$post(this.$api.CAR_MANAGE.OPEN, {
        id,
      }).then(() => {
        this.$Message.success("启用成功");
        this.getList();
      });
    },

    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.id = row.id;
      this.modal = {
        show: true,
        title: "编辑车辆信息",
        submitLoading: false,
      };
      if (row.imgUrl == "img/car.png") {
        this.form.imgUrl = "/imgs/carLogo.jpg";
      } else {
        this.form.imgUrl = this.getImgUrl(row.servicePath, row.imgUrl);
      }
    },
    checkInfo(row) {
      this.carDetail = {
        show: true,
        info: row,
      };
    },

    delete(id) {
      this.$post(this.$api.CAR_MANAGE.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功！");
        this.getList();
      });
    },

    driverBind(row) {
      this.driverDetail = {
        show: true,
        info: row,
      };
    },

    //车辆类型
    getCarType() {
      this.$post(this.$api.CAR_MANAGE.TYPE, {
        pid: 174,
      }).then((res) => {
        this.carType = res.list;
      });
    },
    //车辆品牌
    getCarModel() {
      this.$post(this.$api.CAR_MANAGE.TYPE, {
        pid: 193,
      }).then((res) => {
        this.carModel = res.list;
      });
    },

    //查看图片
    checkPicInfo(row) {
      this.checkPic = true;
      this.imgSrc = row.servicePath + row.imgUrl;
    },
    submit() {
      let params = { ...this.form };
      let url;
      if (params.id) {
        url = this.$api.CAR_MANAGE.UPDATE;
      } else {
        url = this.$api.CAR_MANAGE.ADD;
      }
      params.annualReciew = moment(this.form.annualReciew).format("YYYY-MM-DD");
      if (this.form.clivtaExpire !== "") {
        params.clivtaExpire = moment(this.form.clivtaExpire).format(
          "YYYY-MM-DD"
        );
      }

      if (this.form.vehicleInsurance !== "") {
        params.vehicleInsurance = moment(this.form.vehicleInsurance).format(
          "YYYY-MM-DD"
        );
      }
      params.imgUrl = this.form.imgUrl
        ? "image" + this.form.imgUrl.split("image")[1]
        : "";
      params.type = 2;
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        this.$post(url, params)
          .then(() => {
            this.$Message.success(params.id ? "编辑成功！" : "添加成功！");
            this.getList();
            this.modal.show = false;
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        plate: "",
        carType: "",
        carModel: "",
        gpsType: "",
        gpsModel: "",
        telName: "",
        telPhone: "",
        annualReview: "",
        clivtaExpire: "",
        vehicleInsurance: "",
        carColor: "",
        useUserId: "",
        type: 2,
        imgUrl: "",
        lasting: "",
        wide: "",
        high: "",
        loadSide: "",
        loadHeavy: "",
        allHigh: "",
      };
      this.$refs.form.resetFields();
    },

    loadExpend() {
      let columns = this.table.columns.map((item) => item.title);
      columns.pop();
      let data = this.table.data.map((row) => {
        let rowData = this.table.columns.map((column) => {
          if (column.title == "图片") {
            return this.getImgUrl(row.servicePath, row.imgUrl);
          } else if (column.title == "车牌号/颜色") {
            return row.plate + " ( " + row.carColor + " ) ";
          } else if (column.title == "厢长 / 厢宽 / 厢高(米)") {
            return row.lasting + " × " + row.wide + " × " + row.high;
          } else if (column.title == "载方 / 载重") {
            return `${row.loadSide} 方, ${row.loadHeavy} 吨`;
          } else if (column.title == "整车高度") {
            return `${row.allHigh} 米`;
          }
          return row[column.key];
        });
        return rowData;
      });
      CU.exportExcel(columns, data, "养殖户企业");
    },
  },
  mounted() {
    this.getCarColors();
    this.getGPSList();
    this.getList();
    this.getCarType();
    this.getCarModel();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>