<template>
  <!-- 车辆添加 -->
  <div>
    <Modal
      :value="value"
      title="车辆详情"
      @input="(val) => $emit('input', val)"
      :width="800"
      @on-visible-change="modalChange"
    >
      <Form :label-width="100">
        <Row>
          <Col span="12">
            <FormItem label="车牌号：">
              <span>{{ data.plate }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="车辆类型：">
              <span>{{ data.carType }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="车辆品牌：">
              <span>{{ data.carModel }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="车辆所属：">
              <span>{{ data.companyName }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="厢长：">
              <span>{{ data.lasting }} 米</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="厢宽：">
              <span>{{ data.wide }} 米</span>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="厢高：">
              <span>{{ data.high }} 米</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="载方：">
              <span>{{ data.loadSide }} 方</span>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="载重：">
              <span>{{ data.loadHeavy }} 吨</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="整车高度：">
              <span>{{ data.allHigh }} 米</span>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="GPS品牌：">
              <span>{{ data.gpsType }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="GPS型号：">
              <span>{{ data.gpsModel }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="联系人：">
              <span>{{ data.telName }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="联系电话：">
              <span>{{ data.telPhone }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="交强险期限：">
              <span>{{
                data.clivtaExpire ? data.annualReview.split(" ")[0] : ""
              }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="商业险期限：">
              <span>{{
                data.vehicleInsurance ? data.annualReview.split(" ")[0] : ""
              }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="年审日期：">
              <span>{{
                data.annualReview ? data.annualReview.split(" ")[0] : ""
              }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="车辆颜色：">
              <span>{{ data.carColor }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="车辆图片：">
              <img
                v-if="data.imgUrl!='img/car.png'"
                style="width: 60px; height: 60px; cursor: pointer"
                :src="data.servicePath + data.imgUrl"
                @click="checkPicInfo"
                alt=""
              />
              <img style="width: 60px; height: 60px; cursor: pointer"
               src="/imgs/carLogo.jpg" alt="" v-else>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="() => this.$emit('input', false)">
          确定</Button
        >
        <Button @click="() => this.$emit('input', false)"> 取消</Button>
      </p>
    </Modal>
    <pic-view v-model="checkPic" :src="imgSrc"></pic-view>
  </div>
</template>

<script>
import picView from "@/components/picture_view";
export default {
  name: "",
  components: {
    picView,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    carDetailInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      data: [],
      //查看车辆图片
      checkPic: false,
      //车辆图片地址
      imgSrc: "",
    };
  },
  methods: {
    modalChange(validate) {
      this.data = this.carDetailInfo;
    },
    //查看图片
    checkPicInfo() {
      this.checkPic = true;
      this.imgSrc = this.data.servicePath+this.data.imgUrl
    },
  },
};
</script>

<style lang="less" scoped>
</style>